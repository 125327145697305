import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import styled from 'styled-components';
import {MdDeleteForever} from 'react-icons/md';
import { Spinner } from 'reactstrap';
import { FaRegCopy } from "react-icons/fa";

const ImmobileCard = ({data}) => {

    const types = [
        {type: 'facing_sea', label: 'Frente mar'},
        {type: 'sea_court', label: 'Quadra mar'},
        {type: 'apartament_dif', label: 'Diferenciado'},
        {type: 'roof', label: 'Cobertura'},
        {type: 'plant', label: 'Na Planta'},
        {type: 'others_apartament', label: 'Outros apartamentos'},
        {type: 'house', label: 'Casa'},
        {type: 'terrain', label: 'Terreno residencial'},
        {type: 'terrain_land_shed', label: 'Terreno logístico'},
        {type: 'land_shed', label: 'Galpão logístico'},
    ]

    const [confirm_both, setconfirm_both] = useState(false)
    const [success_dlg, setsuccess_dlg] = useState(false)
    const [dynamic_title, setdynamic_title] = useState("")
    const [dynamic_description, setdynamic_description] = useState("")
    const [loading, setLoading] = useState(false)

    const [selectedId, setSelectedId] = useState(null)
    const [selectedName, setSelectedName] = useState('')

    const handleDuplicate = () => {
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL + '/clone-property/' + data.id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
        })
        .then((res) => res.json())
        .then(response => {
            setTimeout(() => {
                window.open(`${window.location.href}/id?${response.message}`, '_parent')
            }, 0);
        })

    }

    return(
        <>
    <ConstructionCardContainer>
        {data.photos?.length > 0 ?
            <Cover  onClick={() => {
                setTimeout(() => {
                    window.open(`${window.location.href}/id?${data.id}`, '_parent')
                }, 0);
            }} style={{backgroundImage:'url(' + data.photos?.reduce((prevPhoto, currPhoto) => {
                if (!prevPhoto || currPhoto.position < prevPhoto.position) {
                    return currPhoto;
                }
                return prevPhoto;
            }, null)?.medium_image + ')'}}></Cover>
            :
            <Cover onClick={() => {
                setTimeout(() => {
                    window.open(`${window.location.href}/id?${data.id}`, '_parent')
                }, 0);
            }}></Cover>
        }
        <Content onClick={() => {
            setTimeout(() => {
                window.open(`${window.location.href}/id?${data.id}`, '_parent')
            }, 0);
        }}>
            <h5>código: {data.sku} | {data.objective === 'sell' ? 'Venda' : (data.objective === 'rent' ? 'Locação' : '')}</h5>
            <h3>{data.name}</h3>
            <h4>{data.type?.replace(data.type, types?.filter(typeS => typeS.type === data.type)[0]?.label )}</h4>
            <p>{data.city}</p>
        </Content>
        <Duplicate onClick={handleDuplicate}><FaRegCopy /></Duplicate>
        <CloseButton onClick={() => {
                setSelectedId(data.id)
                setSelectedName(data.name)
                setconfirm_both(true)
            }}><MdDeleteForever size="1.5rem" color="#fff"/></CloseButton>

    </ConstructionCardContainer>

    {loading &&
        <Loading>
            <Spinner></Spinner>
        </Loading>
    }


    {confirm_both ? (
            <SweetAlert
                title={`Tem certeza que deseja excluir o imóvel ${selectedName} ?`}
                warning
                showCancel
                cancelButtonText='Cancelar'
                confirmBtnBsStyle="success"
                confirmButtonText='Excluir'
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                    fetch(process.env.REACT_APP_API_URL + '/delete-property/' + selectedId, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                          },
                    })
                    .then((res) => res.json())
                    .then(response => {
                        setconfirm_both(false)
                        setsuccess_dlg(true)
                        setdynamic_title("Deletada")
                        setdynamic_description("Imóvel deletado.")
                    })
                }}
                onCancel={() => {
                setconfirm_both(false)
                setsuccess_dlg(true)
                setdynamic_title("Cancelado")
                setdynamic_description("O imóvel ainda está cadastrada.")
                }}
            >
                Esta exclusão é permanente e não poderá ser revertida.
            </SweetAlert>
            ) : null}

            {success_dlg ? (
                <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 300);
                }}
                >
                {dynamic_description}
                </SweetAlert>
            ) : null}


    </>
    )
}

export default ImmobileCard;

const Duplicate = styled.div`
    position:absolute;
    top:-5px;
    left:-5px;
    background-color:#3E5E3F;
    width:30px;
    height:30px;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;

    & svg{
        color:#fff;
        width:18px;
        height:18px;
    }
`;

const Loading = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:rgba(0,0,0,0.7);
    z-index:9999;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100%;
`;

const ConstructionCardContainer = styled.div`
    width:200px;
    height:260px;
    box-shadow:0 0 5px rgba(0,0,0,0.5);
    border-radius:8px;
    padding:10px;
    transition:0.2s;
    position:relative;
    cursor:pointer;

    &:hover{
        transform:scale(1.03);
    }
`;

const Cover = styled.div`
    background-position:center center;
    background-size:cover;
    width:100%;
    height:100px;
    background-color:#f4f4f4;
`;

const Content = styled.div`
    text-align:left;
    padding-top:10px;

    h5{
        font-size:14px;
        background-color:#cdcdcd;
        border-radius:8px;
        padding:3px 5px;
    }
    h4{
        font-size:17px;
    }
    h3{
        font-size:16px;
    }
`;

const CloseButton = styled.div`
    position:absolute;
    top:-8px;
    z-index:10;
    right:-8px;
    background-color:#B91C1C;
    border-radius:50%;
    cursor:pointer;
    width:30px;
    height:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    box-shadow:0 0 5px rgba(0,0,0,0.3);

    &:hover{
        transform:scale(1.2);
    }
`;