import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from "reactstrap";

const ModalRentControl = ({ isOpen, toggle, onSave }) => {
  const [formData, setFormData] = useState({
    owner: "",
    property_description: "",
    value: "",
    sku: "",
    reserve: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["value", "reserve"].includes(name)) {
      const numericValue = value.replace(/\D/g, "");
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const formatCurrency = (value) => {
    if (!value) return "R$ 0,00";
    const numericValue = parseFloat(value) / 100;
    return `R$ ${numericValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const handleSubmit = () => {
    onSave(formData);
    setFormData({
      owner: "",
      property_description: "",
      value: "",
      sku: "",
      reserve: "",
    });
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Adicionar Novo Aluguel</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="owner">Proprietário</Label>
            <Input
              type="text"
              id="owner"
              name="owner"
              value={formData.owner}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="sku">Código do imóvel</Label>
            <Input
              type="text"
              id="sku"
              name="sku"
              value={formData.sku}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="property_description">Descrição do Imóvel</Label>
            <Input
              type="text"
              id="property_description"
              name="property_description"
              value={formData.property_description}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="value">Valor</Label>
            <Input
              type="text"
              id="value"
              name="value"
              value={formatCurrency(formData.value)}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="reserve">Reserva</Label>
            <Input
              type="text"
              id="reserve"
              name="reserve"
              value={formatCurrency(formData.reserve)}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Salvar
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalRentControl;
