import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import ModalConfirmDelete from "./ModalConfirmDelete";

const ModalEditRentControl = ({ isOpen, toggle, rentControl, onSave }) => {
  const [formData, setFormData] = useState({
    id: "",
    owner: "",
    property_description: "",
    value: "",
    reserve: "",
    sku: "",
  });
  const [rentReminders, setRentReminders] = useState([]);
  const [remindersToDelete, setRemindersToDelete] = useState([]);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [reminderToDelete, setReminderToDelete] = useState(null);
  const [rentTaxes, setRentTaxes] = useState([]);
  const [taxesToDelete, setTaxesToDelete] = useState([]);

  useEffect(() => {
    if (rentControl) {
      setFormData({
        id: rentControl.id || "",
        owner: rentControl.owner || "",
        property_description: rentControl.property_description || "",
        value: rentControl.value || "",
        sku: rentControl.sku || "",
        reserve: rentControl.reserve || "",
      });

      setRentReminders(rentControl.reminders || []);
      setRemindersToDelete([]);

      setRentTaxes(rentControl.taxes || []);
      setTaxesToDelete([]);
    }
  }, [rentControl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["value", "reserve"].includes(name)) {
      const numericValue = value.replace(/\D/g, "");
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const formatCurrency = (value) => {
    if (!value) return "R$ 0,00";
  
    // Garante que o valor seja uma string antes de chamar replace
    const stringValue = String(value);
  
    // Remove qualquer caractere que não seja número antes de converter
    const numericValue = parseFloat(stringValue.replace(/\D/g, "")) / 100;
  
    return `R$ ${numericValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const handleReminderChange = (index, field, value) => {
    const updatedReminders = [...rentReminders];
    if (field === "value") {
      value = value.replace(/\D/g, "");
    }
    updatedReminders[index][field] = value;
    setRentReminders(updatedReminders);
  };

  const handleTaxChange = (index, field, value) => {
    const updatedTaxes = [...rentTaxes];
  
    if (field === "value") {
      // Remove caracteres não numéricos e converte para centavos
      value = value.replace(/\D/g, "");
      updatedTaxes[index][field] = value ? parseFloat(value) / 100 : 0; // Converte para float
    } else {
      updatedTaxes[index][field] = value;
    }
  
    setRentTaxes(updatedTaxes);
  };
  

  const addReminder = () => {
    setRentReminders([
      ...rentReminders,
      { description: "", value: "", date: "" },
    ]);
  };

  const addTax = () => {
    setRentTaxes([...rentTaxes, { name: "", value: "" }]);
  };

  const toggleDeleteModal = (reminder) => {
    setReminderToDelete(reminder || null);
    setModalDeleteOpen(!modalDeleteOpen);
  };

  const handleDeleteReminder = async () => {
    if (!reminderToDelete?.id) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rent-reminders/${reminderToDelete.id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setRentReminders(rentReminders.filter((r) => r.id !== reminderToDelete.id));
        toggleDeleteModal();
        alert("Lembrete excluído com sucesso!");
      } else {
        console.error("Erro ao excluir lembrete:", await response.text());
        alert("Erro ao excluir lembrete.");
      }
    } catch (error) {
      console.error("Erro ao excluir lembrete:", error);
      alert("Erro ao excluir lembrete.");
    }
  };

  const removeTax = (index) => {
    const taxToRemove = rentTaxes[index];
    if (taxToRemove.id) {
      setTaxesToDelete([...taxesToDelete, taxToRemove.id]);
    }
    setRentTaxes(rentTaxes.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      // Atualizar o controle de aluguel
      const rentControlResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/rent-control/${formData.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (!rentControlResponse.ok) {
        throw new Error("Erro ao atualizar o aluguel");
      }

      // Remover lembretes
      for (const id of remindersToDelete) {
        await fetch(`${process.env.REACT_APP_API_URL}/rent-reminders/${id}`, {
          method: "DELETE",
        });
      }

      for (const id of taxesToDelete) {
        await fetch(`${process.env.REACT_APP_API_URL}/rent-taxes/${id}`, {
          method: "DELETE",
        });
      }

      const formattedTaxes = rentTaxes.map((tax) => ({
        ...tax,
        value: parseFloat(String(tax.value).replace(/\D/g, "")) / 100, // Remove "R$" e formata para número
      }));

      for (const tax of formattedTaxes) {
        await fetch(`${process.env.REACT_APP_API_URL}/rent-taxes/${tax.id || ""}`, {
          method: tax.id ? "PUT" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...tax, rent_id: formData.id }),
        });
      }

      // Atualizar ou criar lembretes
      for (const reminder of rentReminders) {
        if (reminder.id) {
          await fetch(
            `${process.env.REACT_APP_API_URL}/rent-reminders/${reminder.id}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ ...reminder, rent_id: formData.id }),
            }
          );
        } else {
          await fetch(`${process.env.REACT_APP_API_URL}/rent-reminders`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...reminder, rent_id: formData.id }),
          });
        }
      }

      alert("Aluguel e lembretes salvos com sucesso!");
      onSave()
      toggle(); // Fechar o modal
    } catch (error) {
      console.error("Erro ao salvar:", error);
      alert("Ocorreu um erro ao salvar os dados.");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Editar Aluguel</ModalHeader>
      <ModalBody>
        <Form>
          <span style={{display:'flex', gap:'20px'}}>
            <FormGroup style={{width:'49%'}}>
              <Label for="owner">Proprietário</Label>
              <Input
                type="text"
                id="owner"
                name="owner"
                value={formData.owner}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup style={{width:'49%'}}>
              <Label for="sku">Código do Imóvel</Label>
              <Input
                type="text"
                id="sku"
                name="sku"
                value={formData.sku}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </span>
          <FormGroup>
            <Label for="property_description">Descrição do Imóvel</Label>
            <Input
              type="text"
              id="property_description"
              name="property_description"
              value={formData.property_description}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <span style={{display:'flex', gap:'20px'}}>
            <FormGroup style={{width:'49%'}}>
              <Label for="value">Valor</Label>
              <Input
                type="text"
                id="value"
                name="value"
                value={formatCurrency(formData.value)}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup style={{width:'49%'}}>
              <Label for="reserve">Reserva</Label>
              <Input
                type="text"
                id="reserve"
                name="reserve"
                value={formatCurrency(formData.reserve)}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </span>

          <div>
          <h5 className="mt-4">Taxas</h5>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Valor</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {rentTaxes.map((tax, index) => (
                <tr key={index}>
                  <td>
                    <Input
                      type="text"
                      value={tax.name}
                      onChange={(e) => handleTaxChange(index, "name", e.target.value)}
                    />
                  </td>
                  <td>
                    <Input
                      type="text"
                      value={rentTaxes[index].value}
                      onChange={(e) => handleTaxChange(index, "value", e.target.value)}
                    />
                  </td>
                  <td>
                    <Button color="danger" size="sm" onClick={() => removeTax(index)}>
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
              {rentTaxes.length === 0 && (
                <tr>
                  <td colSpan="3" className="text-center">Nenhuma taxa adicionada.</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Button color="success" size="sm" onClick={addTax}>
            Adicionar Taxa
          </Button>
          </div>

          <div>
          <h5 className="mt-4">Lembretes</h5>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Dia do Mês</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {rentReminders.map((reminder, index) => (
                <tr key={index}>
                  <td>
                    <Input
                      type="text"
                      value={reminder.description}
                      onChange={(e) =>
                        handleReminderChange(index, "description", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type="text"
                      value={formatCurrency(reminder.value)}
                      onChange={(e) =>
                        handleReminderChange(index, "value", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type="number"
                      min="1"
                      max="31"
                      value={reminder.date}
                      onChange={(e) =>
                        handleReminderChange(index, "date", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => toggleDeleteModal(reminder)}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
              {rentReminders.length === 0 && (
                <tr>
                  <td colSpan="4" className="text-center">
                    Nenhum lembrete adicionado.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Button color="success" size="sm" onClick={addReminder}>
            Adicionar Lembrete
          </Button>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Salvar Alterações
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
      <ModalConfirmDelete
        isOpen={modalDeleteOpen}
        toggle={toggleDeleteModal}
        onConfirm={handleDeleteReminder}
        message={`Tem certeza de que deseja excluir o lembrete "${reminderToDelete?.description}"?`}
      />
    </Modal>
  );
};

export default ModalEditRentControl;
