import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import ptBrLocale from "@fullcalendar/core/locales/pt-br"; 
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from "reactstrap";
import "@fullcalendar/bootstrap/main.css";

const RentControlCalendar = ({ rentControls, onStatusUpdate }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // Evento selecionado
  const [isModalOpen, setIsModalOpen] = useState(false); // Controle do modal
  const [statusPago, setStatusPago] = useState(false); // Estado do toggle
  const [loading, setLoading] = useState(false); // Estado para controle de carregamento

  useEffect(() => {
    const generateEvents = () => {
        const allEvents = [];
        const currentYear = new Date().getFullYear(); // Ano atual
      
        rentControls.forEach((rent) => {
          if (rent.reminders && rent.reminders.length > 0) {
            rent.reminders.forEach((reminder) => {
              // Criar eventos para todos os meses do ano atual
              for (let month = 1; month <= 12; month++) {
                const day = String(reminder.date).padStart(2, "0"); // Garantir dois dígitos no dia
                const eventDate = `${currentYear}-${String(month).padStart(2, "0")}-${day}`;
      
                if (!isNaN(new Date(eventDate).getTime())) {
                  // Verificar o status atual do lembrete para o mês e ano
                  const statusData = reminder.statuses?.find(
                    (status) => status.month === month && status.year === currentYear
                  );
      
                  const status = statusData?.status || "sem_status";
      
                  // Definir cor com base no status
                  let backgroundColor;
                  switch (status) {
                    case "pago":
                      backgroundColor = "#28a745"; // Verde para pagos
                      break;
                    case "nao_pago":
                      backgroundColor = "#ffc107"; // Vermelho para não pagos
                      break;
                    default:
                      backgroundColor = "#ffc107"; // Amarelo para sem status
                  }
      
                  // Adicionar o SKU ao título do evento
                  const eventTitle = `${reminder.description} (${rent.sku || "Sem Código"})`;
      
                  const event = {
                    id: reminder.id, // ID real do lembrete (sem o mês)
                    title: eventTitle,
                    start: eventDate,
                    allDay: true,
                    backgroundColor,
                    extendedProps: {
                      value: reminder.value,
                      rentOwner: rent.owner,
                      property: rent.property_description,
                      sku: rent.sku,
                      status,
                      month, // Adicionar o mês ao evento
                      year: currentYear, // Adicionar o ano ao evento
                    },
                  };
                  allEvents.push(event);
                }
              }
            });
          }
        });
      
        setEvents(allEvents);
      };

    generateEvents();
  }, [rentControls]);

  // Abrir o modal e definir o evento selecionado
const handleEventClick = (info) => {
  setSelectedEvent(info.event);
  setStatusPago(info.event.extendedProps.status === "pago"); // Toggle será marcado se o status for "pago"
  setIsModalOpen(true);
};

  // Salvar o status no backend e fechar o modal
  const handleSave = async () => {
    if (!selectedEvent) return;
  
    setLoading(true);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reminders/${selectedEvent.id}/status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: statusPago ? "pago" : "nao_pago",
            month: selectedEvent.extendedProps.month, // Mês do evento selecionado
            year: selectedEvent.extendedProps.year,   // Ano do evento selecionado
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Erro ao atualizar o status");
      }
  
      // Notificar o componente pai para atualizar os dados
      onStatusUpdate();
  
      // Fechar o modal
      setIsModalOpen(false);
    } catch (error) {
      console.error("Erro ao atualizar o status do lembrete:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, BootstrapTheme]}
        themeSystem="bootstrap"
        initialView="dayGridMonth"
        locale={ptBrLocale}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth",
        }}
        events={events}
        eventClick={handleEventClick} // Função ao clicar em um evento
      />

      {/* Modal */}
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
          Detalhes do Lembrete
        </ModalHeader>
        <ModalBody>
          {selectedEvent && (
            <>
              <p>
                <strong>Lembrete:</strong> {selectedEvent.title}
              </p>
              <p>
                <strong>Valor:</strong> R$ {(selectedEvent.extendedProps.value / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
              </p>
              <p>
                <strong>Proprietário:</strong> {selectedEvent.extendedProps.rentOwner}
              </p>
              <p>
                <strong>Código do Imóvel:</strong> {selectedEvent.extendedProps.sku || "Não Informado"}
              </p>
              <p>
                <strong>Imóvel:</strong> {selectedEvent.extendedProps.property}
              </p>
              <div className="d-flex align-items-center mt-3">
                <Label for="statusPagoToggle" className="me-2">
                  Status:
                </Label>
                <Input
                  type="checkbox"
                  id="statusPagoToggle"
                  checked={statusPago}
                  onChange={(e) => setStatusPago(e.target.checked)}
                />
                <span className="ms-2">{statusPago ? "Pago" : "Não Pago"}</span>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave} disabled={loading}>
            {loading ? "Salvando..." : "Salvar"}
          </Button>
          <Button color="secondary" onClick={() => setIsModalOpen(false)} disabled={loading}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RentControlCalendar;
