import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import styled from "styled-components";
import ModalRentControl from "./ModalRentControl";
import ModalEditRentControl from "./ModalEditRentControl"; // Importar o modal de edição
import RentControlCalendar from "./RentControlCalendar";
import ModalConfirmDelete from "./ModalConfirmDelete";

const PageAlugueis = () => {
  const [rentControls, setRentControls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedRentControl, setSelectedRentControl] = useState(null);

  useEffect(() => {
    fetchRentControls();
  }, []);

  const fetchRentControls = async () => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/rent-control");
      const data = await response.json();
      setRentControls(data);
    } catch (error) {
      console.error("Erro ao buscar rent-controls:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddSave = async (formData) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/rent-control", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        fetchRentControls();
      } else {
        console.error("Erro ao salvar aluguel:", await response.text());
      }
    } catch (error) {
      console.error("Erro ao salvar aluguel:", error);
    }
  };

  const handleEditSave = async (formData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rent-control/${formData.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        fetchRentControls();
      } else {
        console.error("Erro ao editar aluguel:", await response.text());
      }
    } catch (error) {
      console.error("Erro ao editar aluguel:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rent-control/${selectedRentControl.id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        fetchRentControls();
        toggleDeleteModal();
      } else {
        console.error("Erro ao excluir aluguel:", await response.text());
      }
    } catch (error) {
      console.error("Erro ao excluir aluguel:", error);
    }
  };

  const toggleAddModal = () => {
    setModalAddOpen(!modalAddOpen);
  };

  const toggleEditModal = (rentControl) => {
    setSelectedRentControl(rentControl || null);
    setModalEditOpen(!modalEditOpen);
  };

  const toggleDeleteModal = (rentControl) => {
    setSelectedRentControl(rentControl || null);
    setModalDeleteOpen(!modalDeleteOpen);
  };

  const handleStatusUpdate = () => {
    fetchRentControls();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Aluguéis" breadcrumbItem="Gestão de alugueis" />
        <Row>
          <Col lg="12" md="12">
            <Card>
              <CardBody>
                <Button color="primary" onClick={toggleAddModal}>
                  Adicionar Novo Aluguel
                </Button>
                {loading ? (
                  <Load id="load" className="active">
                    <Spinner color="success" />
                  </Load>
                ) : (
                  <Table responsive bordered className="mt-4">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Proprietário</th>
                        <th>Código</th>
                        <th>Descrição do Imóvel</th>
                        <th>Valor</th>
                        {/* <th>Taxa</th> */}
                        <th>Reserva</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rentControls.length > 0 ? (
                        rentControls.map((rent) => (
                          <tr key={rent.id}>
                            <td>{rent.id}</td>
                            <td>{rent.owner}</td>
                            <td>{rent.sku}</td>
                            <td>{rent.property_description}</td>
                            <td>R$ {(rent.value / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</td>
                            {/* <td>R$ {(rent.tax / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</td> */}
                            <td>R$ {(rent.reserve / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</td>
                            <td style={{display:'flex', alignItems:'center', gap:'10px'}}>
                              <Button
                                color="warning"
                                size="sm"
                                onClick={() => toggleEditModal(rent)}
                              >
                                Editar
                              </Button>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => toggleDeleteModal(rent)}
                              >
                                Excluir
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            Nenhum aluguel encontrado.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}

                <div className="mt-5">
                  <h4>Calendário de Lembretes</h4>
                  <RentControlCalendar rentControls={rentControls} onStatusUpdate={handleStatusUpdate} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalRentControl isOpen={modalAddOpen} toggle={toggleAddModal} onSave={handleAddSave} />
      <ModalEditRentControl
        isOpen={modalEditOpen}
        toggle={() => toggleEditModal(null)}
        rentControl={selectedRentControl}
        onSave={handleStatusUpdate}
      />
      <ModalConfirmDelete
        isOpen={modalDeleteOpen}
        toggle={toggleDeleteModal}
        onConfirm={handleDelete}
        message={`Tem certeza de que deseja excluir o aluguel do proprietário "${selectedRentControl?.owner}"?`}
      />
    </React.Fragment>
  );
};

export default PageAlugueis;

const Load = styled.div`
  display: none;

  &.active {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 99999999999999;
  }
`;
